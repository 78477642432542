@import 'assets/scss/_mixins.scss';

.dark {
    color: var(--primaryTextColorDark);
    @include dark-scroll;
}

.light {
    color: var(--primaryTextColorLight);
    @include light-scroll;
}
